import { NavigationButton } from 'libs/growth-platform-brand-system-v2/src/components/NavigationButton';
import { Picto } from 'libs/growth-platform-brand-system-v2/src/components/Picto';
import { applyBgColor } from 'libs/growth-platform-brand-system-v2/src/utils/utils';

import { Breadcrumb, SeparatorType } from '@payfit/growth-platform-brand-system-v2/components/Breadcrumb';
import { fontCaption } from '@payfit/growth-platform-brand-system-v2/styles/typographyNew';
import { graphql } from 'gatsby';
import { CustomIframe } from 'libs/growth-platform-brand-system-v2/src/components/CustomIframe';
import { Section } from 'libs/growth-platform-brand-system-v2/src/components/Section';
import HomeIcon from 'libs/growth-platform-brand-system-v2/src/foundations/pictograms/home.svg';
import { AccordionSection } from 'libs/growth-platform-brand-system-v2/src/templates/AccordionSection';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { HeroMinimal } from 'libs/growth-platform-brand-system-v2/src/templates/HeroMinimal';
import { PayFitColor } from 'libs/growth-platform-brand-system-v2/src/types/module';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import morphism from 'morphism';
import React from 'react';
import { ArcadeScript } from '../../components/Scripts/ArcadeScript';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaAccordionSection } from '../../morphismSchema/templates/schemaAccordionSection';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { schemaHeroMinimal } from '../../morphismSchema/templates/schemaHeroMinimal';
import { linkResolver, Subpath } from '../../utils/linkResolver';
import './styles.scss';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="PillarPage"
      dataName={'datoCmsPillarPage'}
    />
  );
};

export const query = graphql`
  query DatoPillarPage($locale: String, $id: String) {
    datoCmsPillarPage(locale: $locale, id: { eq: $id }) {
      ...PillarPage
    }
  }
`;

const PillarPage = ({ data, pageContext }: any) => {
  const { ...doc } = data.datoCmsPillarPage;
  if (!doc) return null;
  const { accordions, ..._data } = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
  });
  const hero = _data.hero;
  const heroImageProps = hero?.image;
  const buildBreadcrumb = (url: string, title: React.ReactNode) => ({ url, title });
  const breadcrumbsData: { url: string; title: React.ReactNode; }[] = [];
  const addBreadcrumb = (url: string, text: string | React.ReactNode) => {
    breadcrumbsData.push(buildBreadcrumb(url, text));
  };

  // If the page has breadcrumbsText, add the breadcrumbs
  if (doc.breadcrumbsText) {
    // If the page has no parent, add the home icon and the page title
    if (!doc.treeParent) {
      addBreadcrumb('', <HomeIcon className="w-16 h-16 mb-1" />);
      addBreadcrumb('', doc.breadcrumbsText);
    } 
    // If the page has a parent, add the home icon, the parent title and the page title
    else if (doc.treeParent.breadcrumbsText) {
      const parentUrl = linkResolver(
        doc.treeParent,
        pageContext.locale,
        null,
        Subpath.Pillar
      );
      addBreadcrumb(parentUrl, <HomeIcon className="w-16 h-16 mb-1" />);
      addBreadcrumb(parentUrl, doc.treeParent.breadcrumbsText);
      addBreadcrumb('', doc.breadcrumbsText);
    }
  }

  let heroData: any;
  if (hero?.__typename === 'DatoCmsHeroMinimal') {
    heroData = morphism(schemaHeroMinimal, hero);
  }
  if (hero?.__typename === 'DatoCmsHero') {
    heroData = morphism(schemaHero, { ...hero, ...heroImageProps });
  }

  const customIframe = doc?.customIframe?.[0];

  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      tracking={{ pageType: 'pillar' }}
      templateName="PillarPage"
    >
      {doc.breadcrumbsText && (
        <Breadcrumb
          items={breadcrumbsData.map((item, i) => ({
            node: item.url ? (
              <a href={item.url} className="cursor-pointer" key={i}>
                {item.title}
              </a>
            ) : (
              <span key={i}>
                {item.title}
              </span>
            ),
            separator: i > 1 ? SeparatorType.CHEVRON : undefined,
            className: i === 0 ? 'mr-4 mt-1' : '',
          }))}
          objectClass={{
            base: classNames(
              'md:max-w-[720px] xl:max-w-[1248px]',
              'mx-16 md:mx-24 lg:mx-[32px] xl:mx-[100px] xl:mx-auto',
              'mt-[80px] banner-open:mt-[144px] mb-[24px]',
              'truncate',
            ),
            list: 'truncate',
            chevron: 'text-neutral-icon',
            text: classNames('text-default-content truncate', fontCaption),
            textActive: classNames('text-default-content truncate', fontCaption),
          }}
        />
      )}
      {hero?.__typename === 'DatoCmsHeroMinimal' && (
        <HeroMinimal {...heroData} className={classNames(doc.breadcrumbsText ? "" : "mt-80 banner-open:mt-[144px]")} />
      )}
      {hero?.__typename === 'DatoCmsHero' && <Hero {...heroData} />}

      {customIframe &&
        customIframe?.source(
          <div
            className={classNames(
              'IframeSection pt-[34px] md:pt-[52px] pb-[34px] md:pb-[52px]',
              applyBgColor(customIframe.backgroundColor),
            )}
          >
            {customIframe?.source?.includes('arcade.software') && (
              <ArcadeScript />
            )}
            <CustomIframe
              src={customIframe?.source}
              title={customIframe?.title}
            />
          </div>,
        )}
      {accordions && (
        <div
          className={classNames(
            'flex mb-64 px-24 xl:justify-center overflow-scroll xl:overflow-auto',
            applyBgColor(heroData.backgroundColor),
          )}
        >
          <div
            className={classNames(
              'flex justify-center -mx-12 xl:flex-wrap gap-12',
              accordions?.length <= 3 && 'md:w-full',
              customIframe?.backgroundColor &&
                heroData.backgroundColor !== customIframe.backgroundColor
                ? 'py-88'
                : 'pb-88',
            )}
          >
            {accordions?.map(
              (
                {
                  picto,
                  surtitle,
                  imagesOnTheLeft,
                  backgroundColor,
                  body,
                  icon,
                  title,
                  ...accordion
                }: any,
                key: number,
              ) => (
                <ButtonTracking
                  component={NavigationButton}
                  key={key}
                  section="Hero_NavigationButton"
                  {...accordion}
                  className={classNames(
                    'w-[240px]',
                    !heroData?.backgroundColor ||
                      heroData?.backgroundColor === 'white'
                      ? 'border-neutral-51'
                      : 'border-transparent',
                  )}
                  labelTracking={accordion.title}
                >
                  <Picto {...picto} />
                  <div className="flex-1 text-left">{surtitle}</div>
                </ButtonTracking>
              ),
            )}
          </div>
        </div>
      )}
      {accordions?.map((accordion: any, key: any) => {
        return (
          <Section
            key={key}
            topPadding="half"
            bottomPadding={key === accordions.length - 1 ? 'full' : 'half'}
          >
            <ButtonTracking
              component={AccordionSection}
              {...morphism(schemaAccordionSection, {
                ...accordion,
                section: `${key}_DatoCmsAccordion`,
              })}
              labelTracking={accordion.title}
              background={(hero?.backgroundColor as PayFitColor) || 'blue'}
            />
          </Section>
        );
      })}

      {_data?.blocks.length > 0 && (
        <TemplateBlocksZone {..._data} doc={data.datoCmsPillarPage} heroData={heroData} />
      )}
    </Layout>
  );
};

export default PillarPage;
